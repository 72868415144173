import React, { Component } from 'react';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { AppNavbarBrand } from '@coreui/react';
import axios from 'axios';
import cookie from 'react-cookies';

import logo from '../../../assets/img/brand/alahee.com_6.png'

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// import ImageUploader from "react-images-upload";
// import Resizer from "react-image-file-resizer";
// import imageCompression from "browser-image-compression";

import { Label, FormGroup, Button, Card, CardGroup, CardBody, Col, Container, Form, Input, Row } from 'reactstrap';

const base = process.env.REACT_APP_ADMIN_SERVER_URL;
const REACT_APP_FRONT_URL = process.env.REACT_APP_FRONT_URL;

// const Login = React.lazy(() => import('../Login'));

class Rogistar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: true,
      fadeIn: true,
      timeout: 300,
      // stateNext: 'three',
      stateNext: localStorage.getItem('stateNext') || '',
      user_status: localStorage.getItem('user_status') || '',

      userName: '',
      userPassword: '',
      vendorId: '',
      user_type: '',
      inputValue: '',

      bank: '',
      shopFound: '',
      productsCategory: [],
      productsChildCategory: [],
      bannerImagesPreview: '',
      imageName: '',
      image: [],
      incrementState: -1,
      mobileNumberRequired: false,
      shopNameRequired: false,
      brandLogoRequired: false,
      bannerImageRequired: false,
      transactionInformationRequired: false,

      isPersonalImageAllowed: true,
      isShopImageAllowed: true,
      isBannerImageAllowed: true,

      selectedProductCategoryIds: [],

      isRequestInProcess: false,

      vendor: [],
      vendor_details: [],

      mobileNumber: '',
      nationalIdName: '',
      dateOfBirthName: '',
      presentAddress: '',
    };

    this.handleProductChange = this.handleProductChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickTwo = this.handleClickTwo.bind(this);
    this.handleClickThree = this.handleClickThree.bind(this);
  }


  componentDidMount() {
    this.setState({
      // stateNext : 'three',
      stateNext: localStorage.getItem('stateNext'),
      user_status: localStorage.getItem('user_status'),
      userName: localStorage.getItem('userName'),
      userPassword: localStorage.getItem('userPassword'),
      vendorId: localStorage.getItem('employee_id'),
      user_type: localStorage.getItem('user_type'),

      isRequestInProcess: false
    });


    // this.state.stateNext = localStorage.getItem('stateNext');
    // this.state.user_status = localStorage.getItem('user_status');
    // this.state.userName = localStorage.getItem('userName');
    // this.state.userPassword = localStorage.getItem('userPassword');
    // this.state.vendorId = localStorage.getItem('employee_id');
    // this.state.user_type = localStorage.getItem('user_type');
    // this.state.isRequestInProcess = false;

    fetch(base + '/api/parent-category', {
      method: 'GET'
    })
      .then(res => {
        return res.json()
      })
      .then(category => {
        this.setState({
          productsCategory: category.data
        })
        return false;
      });


    // GET THE USR INFOMATION
    fetch(base + `/api/getUserInfo/?id=${localStorage.employee_id}`, {
      method: 'GET'
    })
      .then(res => {
        return res.json()
      })
      .then(users => {
        if (users) {
          let state_Next = '';
          let step_completed = ''; 
          if(users.data_vendor_details[0].step_completed == 'step_one') {
            state_Next = 'two';
            step_completed = 'step_two';
          }
          if(users.data_vendor_details[0].step_completed == 'step_two') {
            state_Next = 'three';
            step_completed = 'step_three';
          }

          this.setState({
            vendor: users.data_vendor[0],
            vendor_details: users.data_vendor_details[0],
            user_status: step_completed,
            mobileNumber: users.data_vendor_details[0].mobile,
            nationalIdName: users.data_vendor_details[0].nid,
            dateOfBirthName: new Date(users.data_vendor_details[0].dob),
            presentAddress: users.data_vendor_details[0].present_address,
          })

          localStorage.setItem('stateNext', state_Next);
          localStorage.setItem('user_status', step_completed);
        }

        console.log('Vendor Details : ', this.state.vendor_details);
        return false;
      });


  } // end of componentDidMount


  handleChange = date => {
    this.setState({
      startDate: date
    });
  };



  handleBackButton() {
    // if (this.state.stateNext == 'two') {
    //   this.setState({ stateNext: '' });
    //   this.setState({ user_status: 'step_one' });
    // }
    // else {
    //   this.setState({ stateNext: 'two' });
    //   this.setState({ user_status: 'step_two' });
    // }

    if ( this.state.stateNext == 'two' || this.state.user_status == 'step_two' ) {
      this.setState({
        stateNext: 'one',
        user_status: 'step_one',
        isRequestInProcess: false
      });
    }
    else if ( this.state.stateNext == 'three' || this.state.user_status == 'step_three' ) {
      this.setState({
        stateNext: 'two',
        user_status: 'step_two',
        isRequestInProcess: false
      });
    }
    else {
      this.setState({
        stateNext: 'one',
        user_status: 'step_one',
        isRequestInProcess: false
      });
    }


    // localStorage.setItem('stateNext', 'one');
    // localStorage.setItem('user_status', 'step_one');
  }

  handleClick(event) {
    event.preventDefault();
    event.stopPropagation();
    event.cancelBubble = true;

    this.setState({ isRequestInProcess: true });

    const data = new FormData();

    if (this.state.image.length > 0) {
      console.log('has value');
      for (const file of this.state.image) {
        data.append('imageFile', file)
      }
    }
    else {
      console.log('no value');
      data.append('imageFile', false);
    }

    if (!this.state.mobileNumber) {
      this.setState({
        mobileNumberRequired: true
      });
    }
    else {
      this.setState({
        mobileNumberRequired: false
      });

      data.append('mobileNumber', JSON.stringify(this.state.mobileNumber));
      data.append('nationalIdName', JSON.stringify(this.state.nationalIdName));
      data.append('image', JSON.stringify(this.state.imageName));
      data.append('dateOfBirthName', JSON.stringify(this.state.dateOfBirthName));
      data.append('presentAddress', JSON.stringify(this.state.presentAddress));
      data.append('vendorId', JSON.stringify(this.state.vendorId));

      var self = this;

      this.setState({
        image: []
      })

      axios({
        method: 'post',
        url: base + '/api/vendor-details-personal',
        data: data,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Atiq ' + cookie.load('token')
        }
      })
        .then(function (response) {
          
          if (response.data.success == true) {
            ToastsStore.success("Vendor Information Succesfully inserted !!");
            
            self.setState({
              bannerImagesPreview: '',
              incrementState: -1
            });

            localStorage.setItem('stateNext', 'two');
            localStorage.setItem('user_status', 'step_two');
            
            // this.setState({ 
            //   stateNext: 'two',
            //   user_status: 'step_two',
            //   isRequestInProcess: false 
            // });
            
            setTimeout(() => {
              self.setState({
                stateNext: 'two',
                user_status: 'step_two',
                isRequestInProcess: false
              });
            }, 2000);

            console.log('this.state.isRequestInProcess == ', this.state.isRequestInProcess);
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  }

  handleClickTwo(event) {
    event.preventDefault();
    event.stopPropagation();
    event.cancelBubble = true;

    this.setState({ isRequestInProcess: true });

    const data = new FormData();

    if (this.state.image.length > 0) {
      console.log('has value');
      for (const file of this.state.image) {
        data.append('imageFile', file)
      }
    }
    else {
      console.log('no value');
      data.append('imageFile', false);
    }

    data.append('shopLanguageName', JSON.stringify(this.state.shopLanguageName));
    data.append('shopCountryName', JSON.stringify(this.state.shopCountryName));
    data.append('shopImage', JSON.stringify(this.state.imageName));
    data.append('shopCurrencyName', JSON.stringify(this.state.shopCurrencyName));
    data.append('your_description', JSON.stringify(this.state.your_description));
    data.append('shopName', JSON.stringify(this.state.shopName));
    data.append('vendorId', JSON.stringify(this.state.vendorId));

    if (!this.state.shopName || this.state.image.length == 0) {
      this.setState({
        shopNameRequired: true,
        brandLogoRequired: true
      });
    }
    else {
      var self = this;

      this.setState({
        shopNameRequired: false,
        brandLogoRequired: false,
        image: []
      });

      axios({
        method: 'post',
        url: base + '/api/vendor-details-shop',
        data: data,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Atiq ' + cookie.load('token')
        }
      })
        .then(function (response) {
          console.log(response);
          if (response.data.success == true) {
            ToastsStore.success("Shop Information Succesfully inserted !!");

            localStorage.setItem('stateNext', 'three');
            localStorage.setItem('user_status', 'step_three');
            // this.setState({ isRequestInProcess: false });

            self.setState({
              bannerImagesPreview: '',
              tradeLicenceName: '',
              incrementState: -1
            });


            setTimeout(() => {
              self.setState({
                stateNext: 'three',
                user_status: 'step_three',
                isRequestInProcess: false
              });
            }, 2000);
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
    return false;
  }

  handleClickThree(event) {
    event.preventDefault();
    event.stopPropagation();
    event.cancelBubble = true;

    this.setState({ isRequestInProcess: true });

    const data = new FormData();

    if (this.state.image.length > 0) {
      console.log('has value');
      for (const file of this.state.image) {
        data.append('imageFile', file)
      }
    }
    else {
      console.log('no value');
      data.append('imageFile', false);
    }

    data.append('tradeLicenceName', JSON.stringify(this.state.tradeLicenceName));
    data.append('businessStartDateName', JSON.stringify(this.state.businessStartDateName));
    data.append('businessImage', JSON.stringify(this.state.imageName));
    data.append('tinName', JSON.stringify(this.state.tinName));
    data.append('vat_registration', JSON.stringify(this.state.vat_registration));
    data.append('businessAddressName', JSON.stringify(this.state.businessAddressName));
    data.append('webAddressName', JSON.stringify(this.state.webAddressName));
    data.append('transactionInformationName', JSON.stringify(this.state.transactionInformationName));
    data.append('vendorCategoryName', JSON.stringify(this.state.vendorCategoryName));
    data.append('productCategoryName', JSON.stringify(this.state.productCategoryName));
    data.append('selectedProductCategoryIds', JSON.stringify(this.state.selectedProductCategoryIds));
    data.append('TermsAndConditionName', JSON.stringify(this.state.TermsAndConditionName));
    data.append('vendorId', JSON.stringify(this.state.vendorId));

    data.append('bankName', JSON.stringify(this.state.bankName));
    data.append('account_name', JSON.stringify(this.state.account_name));
    data.append('ac_no', JSON.stringify(this.state.ac_no));
    data.append('branch', JSON.stringify(this.state.branch));
    data.append('routing_no', JSON.stringify(this.state.routing_no));

    var self = this;

    console.log('Transaction Information : ', this.state.transactionInformationName);

    if (this.state.image.length == 0 || !this.state.transactionInformationName) {
      this.setState({
        bannerImageRequired: true,
        transactionInformationRequired: true
      })
    }
    else {
      this.setState({
        bannerImageRequired: false,
        transactionInformationRequired: false
      })

      axios({
        method: 'post',
        url: base + '/api/vendor-details-business',
        data: data,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Atiq ' + cookie.load('token')
        }
      })
        .then(function (response) {
          console.log(response);

          if (response.data.success == true) {
            ToastsStore.success("Business Information Succesfully inserted !!");

            localStorage.setItem('stateNext', '');
            localStorage.setItem('user_status', 'completed');
            // this.setState({ isRequestInProcess: false });

            self.setState({
              bannerImagesPreview: '',
              incrementState: -1,

              stateNext: '',
              user_status: 'completed',
              isRequestInProcess: false
            });

            // this.props.history.push("/");      
            window.location = '/login'
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  }

  handleMultipleSelectChange = (event) => {
    let opts = [], opt;
    for (let i = 0, len = event.target.options.length; i < len; i++) {
      opt = event.target.options[i];
      if (opt.selected) {
        opts.push(opt.value);
      }
    }
    this.setState({ selectedProductCategoryIds: opts });
  };

  handleChildCategory = (event) => {
    console.log('parent value : ', event.target.value);

    fetch(base + `/api/child-category/?id=${event.target.value}`, {
      method: 'GET'
    })
      .then(res => {
        console.log(res);
        return res.json()
      })
      .then(category => {
        console.log(category.data);

        this.setState({
          productsChildCategory: category.data
        })

        return false;
      });

  }

  handleProductChange(event) {
    event.preventDefault();
    event.stopPropagation();
    event.cancelBubble = true;
    // this.setState({value: event.target.value});
    // alert(event.target.input.files[0]);

    if (event.target.name == 'image' || event.target.name == 'shopImage' || event.target.name == 'businessImage') {
      console.log('Image : ', event.target.name);

      let reader = new FileReader();
      let file = event.target.files[0];
      let eventName = event.target.name;

      console.log(file);

      // STARTED FOR IMAGE FILE SIZE
      let img = document.createElement('img');

      img.src = URL.createObjectURL(event.target.files[0]);

      let imgWidth = 0, imgHeight = 0;

      console.log(img);

      console.log('before onload function : executed')
      img.onload = function () {
        console.log(img.width + ' - ' + img.height);

        imgWidth = img.width;
        imgHeight = img.height;
      };

      // start image dimesion limitation
      console.log('Image before checking : ', event.target.name);
      let eventFileName = event.target.files[0].name;
      setTimeout(() => {

        if (eventName == 'image' && imgWidth == imgHeight) {
          this.setState({
            isPersonalImageAllowed: true
            // isSubmitAllowed: true
          });
          this.state.image.push(file);

          this.setState({
            incrementState: ++this.state.incrementState,
            imageName: eventFileName
          })

          reader.onloadend = () => {
            this.setState({ bannerImagesPreview: [...this.state.bannerImagesPreview, reader.result] });
          }

          reader.readAsDataURL(file);
        }
        else if (eventName == 'shopImage' && (imgWidth == imgHeight || (imgWidth / 2) == imgHeight)) {
          this.setState({
            isShopImageAllowed: true
          });
          this.state.image.push(file);
          console.log('image state update ... ', this.state.image);
          this.setState({
            incrementState: ++this.state.incrementState,
            imageName: eventFileName
          })

          reader.onloadend = () => {
            this.setState({ bannerImagesPreview: [...this.state.bannerImagesPreview, reader.result] });
          }

          reader.readAsDataURL(file);
        }
        else if (eventName == 'businessImage' && imgWidth / 3 == imgHeight) {
          this.setState({
            isBannerImageAllowed: true
          });
          this.state.image.push(file);

          this.setState({
            incrementState: ++this.state.incrementState,
            imageName: eventFileName
          })

          reader.onloadend = () => {
            this.setState({ bannerImagesPreview: [...this.state.bannerImagesPreview, reader.result] });
          }

          reader.readAsDataURL(file);
        }
        else {
          this.setState({
            isPersonalImageAllowed: false,
            isShopImageAllowed: false,
            isBannerImageAllowed: false
          })
        }
      }, 500);
      console.log('outside Width : ' + imgWidth + ' height : ' + imgHeight);
      console.log('File event : ', event.target.files[0].name);
      console.log(this.state.bannerImagesPreview);
      console.log(this.state.image);
    }
    else {
      let target = event.target;
      let value = target.type === 'checkbox' ? target.checked : target.value;
      let name = target.name;

      this.setState({
        [name]: value
      });
    }
    return false;
  }

  changeTransactionSystem = (event) => {
    console.log('changeTransactionSystem : ', event.target.value);

    if (event.target.value == 'Cash') {
      this.state.bank = 'false';
    }
    else if (event.target.value == 'Bank') {
      this.state.bank = 'true';
    }
    else {
      console.log('Please Select at least one element!')
    }

    console.log(this.state.print_bank_infos_name);
  }

  handleShopName(event) {
    this.setState({
      inputValue: event.target.value
    }, () => {
      fetch(base + '/api/checkShopExist', {
        method: "POST",
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(this.state)
      })
        .then((result) => result.json())
        .then((info) => {
          if (info.message) {
            this.setState({ shopFound: 'yes' });
          }
          else {
            this.setState({ shopFound: 'no' });
          }
        })
    });
  }

  render() {
    // const currentState = localStorage.getItem('stateNext');
    // const user_status = localStorage.getItem('user_status');

    const step = this.state.stateNext ? this.state.stateNext : this.state.user_status;

    console.log("this.state.stateNext : ", this.state.stateNext, ", this.state.user_status : ",this.state.user_status, ", step : ", step);


    if ( step == 'two' || step == 'step_two' ) {
      return (
        <>

          {this.state.isRequestInProcess &&
            <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", background: "black", opacity: ".5", zIndex: "999" }}>
              <Loader
                type="Oval"
                color="#009345"
                height={100}
                width={100}
                style={{ position: "fixed", top: "50%", left: "calc(50% - 50px)" }}
              />
            </div>
          }

          <div id="nextShop" className="app flex-row align-items-center" style={{ height: "auto" }}>
            <Container>
              <Row className="justify-content-center">
                <ToastsContainer store={ToastsStore} />
                <Col md="6">
                  <CardGroup>
                    <Card>
                      <CardBody>
                        <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onChange={this.handleProductChange}>
                          <center>
                            <AppNavbarBrand
                              full={{ src: logo, width: 200, alt: 'Alahee Logo' }}
                              href={`${REACT_APP_FRONT_URL}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            />
                            <a
                              href={`${REACT_APP_FRONT_URL}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            ></a>
                          </center>

                          <h2>Shop Preferences</h2>

                          <p className="text-muted">Lets get started, Tell us about your Shop</p>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="shopName"> Shop Name* </Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input onChange={this.handleShopName.bind(this)} className={this.state.shopFound == "yes" ? "is-valid form-control" : this.state.shopFound == "no" ? "is-invalid form-control" : ''} value={this.state.inputValue} placeholder="Shop Name" type="text" name="shopName" id="shopNameId" required>
                              </Input>
                              <React.Fragment>
                                {
                                  this.state.shopFound == "yes" ?
                                    <div style={{ marginLeft: "10%", fontSize: "15px" }} className="valid-feedback">Shop Name Available</div>
                                    : this.state.shopFound == "no" ?
                                      <div style={{ marginLeft: "10%", fontSize: "15px" }} className="invalid-feedback">Shop Name Unvailable</div>
                                      : ''
                                }
                              </React.Fragment>
                              {
                                this.state.shopNameRequired == true ?
                                  <p>**Shop name is required</p>
                                  :
                                  null
                              }
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="categoryName">Shop/Brand Image (1:1 or 2:1)*</Label>
                            </Col>
                            <Col xs="12" md="8">
                              {
                                this.state.bannerImagesPreview ? <img src={this.state.bannerImagesPreview[this.state.incrementState]} height="100" width="100"></img>
                                  : ''
                              }
                              <Input type="file" id="shopImage" name="shopImage" data-name={'shopImage'} required />
                              {
                                this.state.brandLogoRequired == true ?
                                  <p>**Image is required</p>
                                  :
                                  null
                              }
                              {
                                this.state.isShopImageAllowed == false ? <p>**Shop/Brand Image need to be 1:1 or 2:1</p> : null
                              }
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="shopLanguage">Shop Language </Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input type="select" name="shopLanguageName" id="shopLanguageId" value={this.state.shopLanguageName}>
                                {/* <option value="">Select</option> */}
                                <option value="ENG">English</option>
                                {/* <option value="BAN">Bangla</option> */}
                              </Input>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="shopCountry">Shop Country </Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input type="select" name="shopCountryName" id="shopCountryId" value={this.state.shopCountryName}>
                                {/* <option value="">Select</option> */}
                                <option value="BAN">Bangladesh</option>
                                {/* <option value="IND">India</option> */}
                              </Input>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="shopCurrency">Shop Currency </Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input type="select" name="shopCurrencyName" id="shopCurrencyId" value={this.state.shopCurrencyName}>
                                {/* <option value="">Select</option> */}
                                <option value="bdt">BDT</option>
                                {/* <option value="dollar">Dollar</option> */}
                              </Input>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label>Which of these best describes you?</Label>
                            </Col>
                            <Col md="8">
                              <FormGroup check className="radio">
                                <Input className="form-check-input" type="radio" id="radio1" name="your_description" value="option1" />
                                <Label check className="form-check-label" htmlFor="radio1">Selling is my full time job</Label>
                              </FormGroup>
                              <FormGroup check className="radio">
                                <Input className="form-check-input" type="radio" id="radio2" name="your_description" value="option2" />
                                <Label check className="form-check-label" htmlFor="radio2">I sell part time</Label>
                              </FormGroup>
                              <FormGroup check className="radio">
                                <Input className="form-check-input" type="radio" id="radio3" name="your_description" value="option3" />
                                <Label check className="form-check-label" htmlFor="radio3">Other</Label>
                              </FormGroup>
                            </Col>
                          </FormGroup>

                          <Button type="button" onClick={this.handleClickTwo.bind(this)} color="success" block >Save and Continue</Button>
                          <Button type="button" onClick={this.handleBackButton.bind(this)} style={{ color: "a6a8ab" }} block>Back</Button>
                        </Form>
                      </CardBody>
                    </Card>
                  </CardGroup>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
    else if ( step == 'three' || step == 'step_three' ) {
      return (
        <>

          {this.state.isRequestInProcess &&
            <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", background: "black", opacity: ".5", zIndex: "999" }}>
              <Loader
                type="Oval"
                color="#009345"
                height={100}
                width={100}
                style={{ position: "fixed", top: "50%", left: "calc(50% - 50px)" }}
              />
            </div>
          }

          <div id="nextShop" className="app flex-row align-items-center" style={{ height: "auto" }}>
            <Container>
              <Row className="justify-content-center">
                <ToastsContainer store={ToastsStore} />
                <Col md="6">
                  <CardGroup>
                    <Card>
                      <CardBody>
                        <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onChange={this.handleProductChange}>
                          <center>
                            <AppNavbarBrand
                              full={{ src: logo, width: 200, alt: 'Alahee Logo' }}
                              href={`${REACT_APP_FRONT_URL}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            />
                            <a
                              href={`${REACT_APP_FRONT_URL}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            ></a>
                          </center>

                          <h2>Business Preferences</h2>

                          <p className="text-muted">Lets get started, Tell us about your business</p>


                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="tradeLicence"> Trade Licence </Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input type="text" placeholder="Trade Liscense" name="tradeLicenceName" id="tradeLicenceId" value={this.state.tradeLicenceName}>

                              </Input>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="businessStartDate">Business Start Date</Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input type="date" name="businessStartDateName" id="businessStartDateId" placeholder="date" value={this.state.businessStartDateName} />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="categoryName">Banner Image (9:3)*</Label>
                            </Col>
                            <Col xs="12" md="8">
                              {
                                this.state.bannerImagesPreview ? <img src={this.state.bannerImagesPreview[this.state.incrementState]} height="112" width="200"></img>
                                  : ''
                              }
                              <Input type="file" id="businessImage" name="businessImage" data-name={'businessImage'} required />
                              {
                                this.state.bannerImageRequired == true ?
                                  <p>**Banner image is required</p>
                                  :
                                  null
                              }
                              {
                                this.state.isBannerImageAllowed == false ? <p>**Banner Image need to be 9:3</p> : null
                              }
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="tinId">TIN </Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input placeholder="TIN Number " type="text" name="tinName" id="tinId" value={this.state.tinName}>

                              </Input>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="vat_registration">VAT Reg. No.</Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input placeholder="VAT Registration No." type="text" name="vat_registration" id="vat_registration" value={this.state.vat_registration}>

                              </Input>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="businessAddress">Business Address </Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input placeholder="Business Address  " type="textarea" name="businessAddressName" id="businessAddressId" required={true} value={this.state.businessAddressName}>

                              </Input>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="webAddress">Web/Facebook Address </Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input placeholder="Web Address" type="text" name="webAddressName" id="webAddressId" required={true} value={this.state.webAddressName}>

                              </Input>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="transactionInformation">Transaction Type* </Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input type="select" name="transactionInformationName" id="transactionInformationId" required={true} onChange={this.changeTransactionSystem} value={this.state.transactionInformationName}>
                                <option value="">Select</option>
                                <option value="Cash">Cash</option>
                                <option value="Bank">Bank</option>
                              </Input>
                              {
                                this.state.transactionInformationRequired == true ?
                                  <p>**Transaction information is required</p>
                                  :
                                  null
                              }
                            </Col>
                          </FormGroup>

                          {/* If Bank then need add another option which is call bank details */}

                          {
                            this.state.bank == 'true' ?
                              <React.Fragment>
                                <FormGroup row>
                                  <Col md="4">
                                    <Label>Bank Name*</Label>
                                  </Col>
                                  <Col xs="12" md="8">
                                    <Input placeholder="Bank Name" type="text" name="bankName" id="bankName" required={true} value={this.state.bankName}>

                                    </Input>
                                  </Col>
                                </FormGroup>

                                <FormGroup row>
                                  <Col md="4">
                                    <Label>Account Name*</Label>
                                  </Col>
                                  <Col xs="12" md="8">
                                    <Input placeholder="Account Name" type="text" name="account_name" id="account_name" required={true} value={this.state.account_name}>

                                    </Input>
                                  </Col>
                                </FormGroup>

                                <FormGroup row>
                                  <Col md="4">
                                    <Label> Account No* </Label>
                                  </Col>
                                  <Col xs="12" md="8">
                                    <Input placeholder="Account No" type="text" name="ac_no" id="ac_no" required={true} value={this.state.ac_no}>

                                    </Input>
                                  </Col>
                                </FormGroup>

                                <FormGroup row>
                                  <Col md="4">
                                    <Label>Branch*</Label>
                                  </Col>
                                  <Col xs="12" md="8">
                                    <Input placeholder="Branch" type="text" name="branch" id="branch" required={true} value={this.state.branch}>

                                    </Input>
                                  </Col>
                                </FormGroup>

                                <FormGroup row>
                                  <Col md="4">
                                    <Label>Routing No*</Label>
                                  </Col>
                                  <Col xs="12" md="8">
                                    <Input placeholder="Routing No" type="text" name="routing_no" id="routing_no" required={true} value={this.state.routing_no}>

                                    </Input>
                                  </Col>
                                </FormGroup>
                              </React.Fragment>
                              :
                              null
                          }

                          {/* <FormGroup row>
                          <Col md="4">
                            <Label htmlFor="vendorCategory">Vendor Category</Label>
                          </Col>
                          <Col xs="12" md="8">
                            <Input type="select" name="vendorCategoryName" id="vendorCategoryId" value={this.state.vendorCategoryName}>
                              <option value="Standard">Standard</option>
                              <option value="Gold">Gold</option>
                            </Input>
                          </Col>
                        </FormGroup> */}

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="productCategory">Product Category* </Label>
                            </Col>
                            <Col xs="12" md="8">
                              {/* <Input 
                            type="select"
                            name="productCategoryName" 
                            id="productCategoryId" 
                            required={true} 
                            onChange={this.handleChildCategory} 
                            value={this.state.productCategoryName}> */}

                              <Input
                                type="select"
                                name="productCategoryIds"
                                id="productCategoryIds"
                                required={true}
                                onChange={this.handleMultipleSelectChange}
                                value={this.state.selectedProductCategoryIds}
                                multiple
                              >
                                {
                                  this.state.productsCategory.map((productsCategoryValue, key) =>
                                    <option key={key} value={productsCategoryValue.id} >
                                      {productsCategoryValue.category_name}
                                    </option>
                                  )
                                }

                                {/* {this.state.productsCategory.map(
                                  (productsCategoryValue, key) => (
                                    <option value={key} key={key}>
                                      {" "}
                                      {productsCategoryValue}{" "}
                                    </option>
                                  )
                                )} */}
                              </Input>
                            </Col>
                          </FormGroup>

                          {
                            this.state.productsChildCategory.length > 0 ?
                              <FormGroup row>
                                <Col md="4">
                                  <Label htmlFor="productCategory">Child Product Category </Label>
                                </Col>
                                <Col xs="12" md="8">
                                  <Input type="select" name="productCategoryChildName" id="productCategoryChildId" value={this.state.productCategoryChildName}>
                                    <option value="0">Please select</option>
                                    {
                                      this.state.productsChildCategory.map((productsCategoryValue, key) =>
                                        <option key={key} value={productsCategoryValue.id} > {productsCategoryValue.category_name} </option>
                                      )
                                    }
                                  </Input>
                                </Col>
                              </FormGroup> :
                              null
                          }

                          <FormGroup row>

                            <Col md="4">
                              <Label htmlFor="TermsAndCondition"> Terms and Conditions </Label>
                            </Col>

                            <Col md="4">
                              <Label htmlFor="productCategory" className="ml-3"> <Input required={true} type="checkbox" id="TermsAndConditionId" name="TermsAndConditionName" value="Yes" /> I agree </Label>
                            </Col>

                            <Col md="4">
                              <a href="#">see more</a>
                            </Col>
                          </FormGroup>

                          <Button type="button" onClick={this.handleClickThree.bind(this)} color="success" block >Save and Continue</Button>
                          <Button type="button" onClick={this.handleBackButton.bind(this)} style={{ color: "a6a8ab" }} block>Back</Button>
                        </Form>
                      </CardBody>
                    </Card>
                  </CardGroup>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
    else {
      return (
        <>

          {this.state.isRequestInProcess &&
            <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", background: "black", opacity: ".5", zIndex: "999" }}>
              <Loader
                type="Oval"
                color="#009345"
                height={100}
                width={100}
                style={{ position: "fixed", top: "50%", left: "calc(50% - 50px)" }}
              />
            </div>
          }

          <div id="nextShop" className="app flex-row align-items-center" style={{ height: "auto" }}>
            <Container>
              <Row className="justify-content-center">
                <ToastsContainer store={ToastsStore} />
                <Col md="6">
                  <CardGroup>
                    <Card>
                      <CardBody>
                        <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onChange={this.handleProductChange}>
                          <center>
                            <AppNavbarBrand
                              full={{ src: logo, width: 200, alt: 'Alahee Logo' }}
                              href={`${REACT_APP_FRONT_URL}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            />
                            <a
                              href={`${REACT_APP_FRONT_URL}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            ></a>
                          </center>

                          <h2>Personal Preferences</h2>

                          <p className="text-muted">Lets get started, Tell us about you</p>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="mobile">Mobile* </Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input type="text" name="mobileNumber" id="mobileNumberId" defaultValue={this.state.mobileNumber} required>
                              </Input>
                              {
                                this.state.mobileNumberRequired == true ?
                                  <p>**Mobile Number is Required</p>
                                  : null
                              }
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="nationalId">National ID </Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input type="text" name="nationalIdName" id="nationalIdsId" defaultValue={this.state.nationalIdName}>
                              </Input>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="dateOfBirth">Date of Birth</Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input type="date" name="dateOfBirthName" id="dateOfBirthId" defaultValue={this.state.dateOfBirthName} />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="address">Present Address </Label>
                            </Col>
                            <Col xs="12" md="8">
                              <Input type="textarea" name="presentAddress" id="presentAdressId" defaultValue={this.state.presentAddress}>
                              </Input>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md="4">
                              <Label htmlFor="categoryName">Profile Image (1:1)</Label>
                            </Col>
                            <Col xs="12" md="8">
                              {
                                this.state.bannerImagesPreview ? <img src={this.state.bannerImagesPreview[this.state.incrementState]} height="100" width="100"></img>
                                  : ''
                              }
                              <Input type="file" id="image" name="image" data-name={'image'} />
                              {
                                this.state.isPersonalImageAllowed == false ? <p>**Personal Image need to be 1:1</p> : null
                              }
                            </Col>
                          </FormGroup>

                          <Button type="button" onClick={this.handleClick.bind(this)} color="success" block >Save and Continue</Button>
                        </Form>
                      </CardBody>
                    </Card>
                  </CardGroup>
                </Col>
              </Row>
            </Container>

          </div>
        </>
      );
    }


  }
}

export default Rogistar;
